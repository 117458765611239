<template>
  <div>
    <nav-bar>
      <div class="edition-center">
        <div class="pay-container">
          <div class="title-bar">Transferencia con soporte de pago：</div>
          <div class="pay-content">
            <img src="@/assets/order/line.png" />
            <div class="payInfo">
              <div>
                <div>Monto de la remesa：</div>
                <div>COP {{ pricePay | filterPrice }}</div>
              </div>
              <div>
                <div>Número de cuenta del beneficiario：</div>
                <div>{{ accountDetail.account }}</div>
              </div>
              <div>
                <div>Nombre de la cuenta del beneficiario：</div>
                <div>{{ accountDetail.payee }}</div>
              </div>
              <div>
                <div>NIT：</div>
                <div>{{ accountDetail.socialCode }}</div>
              </div>
              <div>
                <div>Cuenta bancaria：</div>
                <div>{{ accountDetail.bankName }}</div>
              </div>
              <div>
                <div>Tipo de cuenta：</div>
                <div>{{ accountDetail.accountType }}</div>
              </div>
            </div>
            <img src="@/assets/order/line.png" alt="" />
          </div>
        </div>
        <div class="pay-container" style="padding-left: 40px">
          <div class="title-bar">Cargue el recibo de pago：</div>
          <div class="addBox">
            <div class="add">
              <upload-image
                folder="head"
                @listenPostImage="postImage"
                type="images"
              />
            </div>
            <div class="add" v-if="temp.payCertificateUrl">
              <img
                @click="
                  $store.commit('SHOW_IMAGE_WALL', temp.payCertificateUrl)
                "
                :src="temp.payCertificateUrl | filterPicture"
                alt=""
              />
            </div>
          </div>
        </div>

        <div class="submit">
          <el-button
            type="success"
            round
            :loading="btnLoading"
            style="width: 400px"
            @click="payOrder"
          >
            Enviar
          </el-button>
        </div>
      </div>
    </nav-bar>
  </div>
</template>

<script>
import navBar from '@/components/navBar/index.vue'
import UploadImage from '@/components/upload/image'
export default {
  components: {
    navBar,
    UploadImage
  },

  data() {
    return {
      temp: {
        orderIdOrSN: '',
        receivingAccountId: '',
        payCertificateUrl: ''
      },
      pricePay: '',
      billingCertificateType: '',
      accountDetail: '',
      btnLoading: false
    }
  },

  created() {
    if (this.$route.params.orderId) {
      this.temp.orderIdOrSN = this.$route.params.orderId
      this.pricePay = this.$route.params.totalPrice
      this.billingCertificateType = this.$route.params.billingCertificateType
    } else {
      this.$router.back(-1)
      return
    }

    console.log(this.$route.params.orderId)

    let type =
      this.billingCertificateType === 'RECEIPT'
        ? 'PRIVATE'
        : this.billingCertificateType === 'INVOICE'
        ? 'PUBLIC'
        : 'null'

    this.$axios.post('/receivingAccount/list', { type: type }).then((res) => {
      this.accountDetail = res.data.result.rows[0]
      console.log(this.accountDetail)
    })
  },

  methods: {
    payOrder() {
      this.temp.receivingAccountId = this.accountDetail.id
      if (!this.temp.payCertificateUrl) {
        this.$message.warning('Por favor, suba el certificado de pago')
        return
      }

      this.btnLoading = true
      console.log(this.temp)
      this.$axios
        .post('/order/payOrder', this.temp)
        .then((res) => {
          this.$message.success('Presentación exitosa')
          this.btnLoading = false
          setTimeout(() => {
            this.$router.back(-1)
          }, 2000)
        })
        .catch(() => {
          this.btnLoading = false
        })
    },

    postImage($event) {
      this.temp.payCertificateUrl = $event
    }
  }
}
</script>

<style lang="scss" scoped>
.edition-center {
  width: 1200px;
  margin: 0 auto;
}

.pay-container {
  margin: 40px 0;
  .title-bar {
    font-size: 20px;
    font-weight: bold;
  }
  .pay-content {
    margin-top: 20px;
    img {
      width: 100%;
    }
    .payInfo {
      width: 600px;
      color: #979797;
      font-size: 18px;
      padding: 20px 40px;
      > div {
        display: flex;
        justify-content: space-between;
        color: #606060;
        padding: 3px 0;
      }
    }
  }

  .addBox {
    display: flex;
    .add {
      margin: 20px 20px 0 0;
      width: 120px;
      display: inline-block;
      cursor: pointer;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}

.submit {
  text-align: center;
}
</style>
